import axios from "axios";
import CryptoJS from "crypto-js";

// const baseURL = "https://back.ostocare.co.kr/"; // 라이브 서버
const baseURL = "https://backdev.ostomy.co.kr/"; // 개발 서버
// const baseURL = "http://192.168.0.97:3400/"; // 휘리릭

const common = {
  // baseURL: "http://15.165.140.143:3500/",
  // baseURL: "https://back.ostocare.co.kr/", // 라이브 서버
  baseURL, // 개발 서버
  // baseURL: "http://192.168.0.97:3400/", // 휘리릭
  networkImg: "https://stoma.s3.ap-northeast-2.amazonaws.com/image/",
  networkCont: "https://stoma.s3.ap-northeast-2.amazonaws.com/contents/",
  //admin
  admin: baseURL + "admin/", // 관리자 관련 페이지 주소
  patient: baseURL + "patient/", // Dashboard 환자 정보 관련 주소
  jangruSurvey: baseURL + "jangruSurvey/", // 장루 설문 관련 주소
  jangruReply: baseURL + "jangruReply/", // 장루 응답 관련 주소
  diary: baseURL + "diary/", // 건강 다이어리 관련 주소
  alarm: baseURL + "alarm/", // 알람 관련 주소
  community: baseURL + "community/", //환자 커뮤니티 주소
  fileUpload: baseURL + "upload/oneNew", // 파일 서버에 올리는 주소
  fileUploadMulti: baseURL + "upload/multi", // 파일 여러개 서버에 올리는 주소
  fileUploadDelete: baseURL + "upload/delete", // 파일 서버에서 삭제하는 주소

  //아테네로컬
  //baseURL: "http://192.168.0.35:3500/"
};

export const api = axios.create({
  baseURL: common.baseURL,
});

// CryptoJS AES 128 암호화
var key = "Qsj23missdaxX2Bj";
var text = "US0378331005-USD-US-en";
// Fix: Use the Utf8 encoder
text = CryptoJS.enc.Utf8.parse(text);
// Fix: Use the Utf8 encoder (or apply in combination with the hex encoder a 32 hex digit key for AES-128)
key = CryptoJS.enc.Utf8.parse(key);
// Fix: Apply padding (e.g. Zero padding). Note that PKCS#7 padding is more reliable and that ECB is insecure
// Fix: Pass a CipherParams object (or the Base64 encoded ciphertext)
export function getApiEncrypt(text) {
  // var encObj = CryptoJS.AES.encrypt(text, key);
  // let result = encObj.toString();
  var encrypted = CryptoJS.AES.encrypt(text, key, {
    mode: CryptoJS.mode.ECB,
  });
  encrypted = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  return encrypted;
}

export function getApiDecrypt(encrypted) {
  // const decObj = CryptoJS.AES.decrypt(data, key);
  // return decObj.toString();
  var decrypted = CryptoJS.AES.decrypt(
    { ciphertext: CryptoJS.enc.Base64.parse(encrypted) },
    key,
    { mode: CryptoJS.mode.ECB }
  );

  // Fix: Utf8 decode the decrypted data

  return decrypted.toString(CryptoJS.enc.Utf8);
}

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
    reader.onerror = reject;
  });
}

api.interceptors.request.use(
  async function (config) {
    switch (config.method) {
      case "get":
        // url의 마지막이 인자가 아니라 url의 일부 일때는 암호화하지 않음
        let doEncrypt = true;
        const doNotEncrypt = [
          "answer",
          "belong",
          "community",
          "community/",
          "news",
          "news/inTerm",
          "question",
          "service/apply",
          "surgery",
        ];
        doNotEncrypt.forEach((el) => {
          if (baseURL + el === config.url) {
            doEncrypt = false;
            return;
          }
        });
        console.log(config.url);
        if (doEncrypt) {
          const lastOne =
            config.url.split("/")[config.url.split("/").length - 1];
          let encryptedData = getApiEncrypt(lastOne);
          config.url =
            config.url.split("/").slice(0, -1).join("/") +
            "/" +
            encryptedData.replaceAll("/", "*");
          // encryptedData
          console.log("get >>>>>>>>>", config);
        }

        break;
      case "post":
        if (config.data instanceof FormData) {
          let newData = { file: "", name: "" };
          // console.log(Array.from(config.data.values()));
          // const result = await Promise.all(
          //   Array.from(config.data.values()).map((value, index) => {
          //     let reader = new FileReader();
          //     if (index === 0) {
          //       reader.readAsDataURL(value);
          //       reader.onload = (e) => {
          //         console.log(e.target.result);
          //         newData.file = e.target.result;
          //       };
          //     } else {
          //       newData.name = value;
          //     }
          //   })
          // );

          for (const pair of config.data.entries()) {
            // const reader = new FileReader();
            if (pair[0] === "file") {
              const res = await readFileAsync(pair[1]);
              newData.file = res;
            } else {
              newData.name = pair[1];
            }
          }

          console.log("폼데이터 바꾼거", newData);
          let encryptedData = getApiEncrypt(JSON.stringify(newData));

          config.data = { encryptStr: encryptedData };
        } else if (config.data) {
          console.log("일반포스트");
          let encryptedData = getApiEncrypt(JSON.stringify(config.data));
          config.data = { encryptStr: encryptedData };
        }
        break;

      default:
        break;
    }
    console.log("post!!!!!!!!!!!!!!!!!", config);
    return config;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  function (response) {
    const decryptedData = getApiDecrypt(response.data.encryptStr);
    response.data = JSON.parse(decryptedData);
    console.log(response);
    return response;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  }
);

export default common;
